import React from "react";
import DatePicker from 'react-datepicker'


const DatepickerWrapper = props => {
  let {dateFormat,className, selected, value}=props;
  const date = selected || value
  return (
    <DatePicker
      {...props}
      value={!!date ?date : ''}
      selected={!!date ? date : ''}
      className={className || 'form-control'}
      dateFormat={dateFormat || 'yyyy-MM-dd'}
      disabledKeyboardNavigation={true}
      onKeyDown={(e) => e.preventDefault()}
    />
  )
}

export default DatepickerWrapper